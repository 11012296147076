var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ remMargin:_vm.$store.state.isMobile },attrs:{"id":"lokasi"}},[_c('h1',[_vm._v("Projects")]),(_vm.$store.state.windowWidth > 600 && !_vm.$store.state.isMobile)?_c('div',{staticClass:"flex"},[_c('ul',{staticClass:"loc"},_vm._l((_vm.$store.state.data),function(house,i){return _c('li',{key:i,on:{"mouseover":function($event){return _vm.active(i)},"click":function($event){return _vm.$emit('selected', house);}}},[_c('p',[_vm._v(_vm._s(house.Name))])])}),0),_c('div',{staticClass:"active1"}),(_vm.$store.state.windowWidth > 600 && !_vm.$store.state.isMobile)?_c('GmapMap',{ref:"mapRef",staticClass:"map",attrs:{"center":{ lat: -6.41457487118613, lng: 106.84184069107874 },"zoom":_vm.zoom,"options":{
          styles: _vm.style,
          disableDefaultUI: true,
          gestureHandling: 'cooperative',
          }}},[_c('gmap-info-window',{attrs:{"options":_vm.infoOptions,"position":_vm.activeMarker,"opened":true}}),_c('GmapMarker',{attrs:{"position":_vm.activeMarker,"animation":2,"clickable":false,"draggable":false,"icon":{
            url: require('../assets/marker.svg'),
            size: {width: 78, height: 95, f: 'px', b: 'px',},
            scaledSize: {width: 39, height: 48, f: 'px', b: 'px',},
            anchor: { x: 19.5, y: 48, f: 'px', b: 'px'},
          }}})],1):_vm._e()],1):(_vm.$store.state.windowWidth < 601 || _vm.$store.state.isMobile)?_c('Listing'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }